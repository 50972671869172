import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useEffect, useState } from "react";
import { primarySaleData, secondarySaleData, uploadDocument, uploadDocumentSecondary } from "../services/uploadDocument";
import "./FormDemo.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { errorMessage, successMessage } from "./alertMessage";
import { InputText } from "primereact/inputtext";

const Xyz = () => {
  const [fileName, setFileName] = useState("");
  const [primarySaleDataList, setPrimarySaleDataList] = useState([]);

   const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    const fileInput = event.target.querySelector('input[type="file"]');
    const clientData = fileInput.files[0];

    if (!clientData) {
      console.error("No file selected");
      return;
    }

    uploadDocumentSecondary(clientData)
      .then((response) => {
        console.log(response);
        successMessage(response.data.message);
        fetchData();
      })
      .catch((error) => {
        console.log(error);
        errorMessage(error.message);
      });
  };

  const fetchData = () => {
    const data = {
      startDate: "2024-01-01T19:06:40Z",
      endDate: "2024-01-31T19:06:40Z",
    };
    secondarySaleData(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          console.log("Res", Res);
          setPrimarySaleDataList(Res);
          successMessage(response.data.message);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => fetchData(), []);

  console.log("primarySaleDataList", primarySaleDataList);

  const paginatorLeft = (
    <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  );


   const onGlobalFilterChange1 = (e) => {
     const value = e.target.value;

     setGlobalFilterValue1(value);
   };

   const renderHeader1 = () => {
     return (
       <div className="flex justify-content-between">
         <span className="p-input-icon-left">
           <i className="pi pi-search" />
           <InputText
             value={globalFilterValue1}
             onChange={onGlobalFilterChange1}
             placeholder="Keyword Search"
           />
         </span>
       </div>
     );
   };

   const header1 = renderHeader1();

   console.log("globalFilterValue1", globalFilterValue1);

  return (
    <div className="background-image2">
      <div className="surface-0 text-700 text-center">
        <div className="text-blue-600 font-bold mb-3">
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "216px" }} src="/Img/Logo.png" alt="logo" />
          </div>
          &nbsp;POWERED BY Dr.Oetker
        </div>
        <div className="text-900 font-bold text-5xl mb-3 color-code">
          Secondary Sale - Kartik Traders
        </div>
        <form onSubmit={handleFileUpload}>
          <div className="row mb-3">
            <div className="col-md-12">
              <label for="file-upload" className="custom-file-upload">
                <i className="pi pi-upload"></i> Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {fileName && (
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: 14,
                    fontWeight: 600,
                    margin: 10,
                    color:'#fff'
                  }}
                >
                  {fileName}
                </p>
              )}
            </div>
          </div>
          <Button
            label="Upload"
            type="submit"
            icon="pi pi-discord"
            className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
          />
        </form>
      </div>
      <div>
        <div className="card" style={{ height: "calc(135vh - 145px)" }}>
          <h5></h5>
          <DataTable
            value={primarySaleDataList}
            paginator
            scrollable
            scrollHeight="flex"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            dataKey="id"
            filterDisplay="menu"
            globalFilter={globalFilterValue1}
            header={header1}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            className="p-datatable-customers"
            showGridlines
          >
            <Column
              field="orderNo"
              header="Order No"
              style={{ width: "12%" }}
            ></Column>
            <Column
              field="orderDate"
              header="Order Date"
              style={{ width: "16%" }}
            ></Column>
            <Column
              field="outletName"
              header="Outlet Name"
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="product.skuname"
              header="Sku Name"
              style={{ width: "25%" }}
            ></Column>
            <Column
              field="quantityStdUnit"
              header="Quantity Std Unit"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="saleValue"
              header="Sale Value"
              style={{ width: "15%" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Xyz;
