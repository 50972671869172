import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useEffect, useState } from "react";
import {
  claimProcessData,
  claimProcessDetails,
  primarySaleData,
  secondarySaleData,
  uploadDocument,
  uploadDocumentSecondary,
} from "../services/uploadDocument";
import "./FormDemo.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";

const ClaimProcess = () => {
    const navigate = useNavigate();
  const [primarySaleDataList, setPrimarySaleDataList] = useState([]);

   const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  const fetchData = () => {
    const data = {
      startDate: "2024-01-01T19:06:40Z",
      endDate: "2024-01-31T19:06:40Z",
    };
    claimProcessData(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          console.log("Res", Res);
          setPrimarySaleDataList(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => fetchData(), []);

  console.log("primarySaleDataList", primarySaleDataList);

  const paginatorLeft = (
    <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  );

   const onGlobalFilterChange1 = (e) => {
     const value = e.target.value;

     setGlobalFilterValue1(value);
   };

   const renderHeader1 = () => {
     return (
       <div className="flex justify-content-between">
         <span className="p-input-icon-left">
           <i className="pi pi-search" />
           <InputText
             value={globalFilterValue1}
             onChange={onGlobalFilterChange1}
             placeholder="Keyword Search"
           />
         </span>
       </div>
     );
   };

   const header1 = renderHeader1();

   console.log("globalFilterValue1", globalFilterValue1);

  return (
    <div className="background-image3">
      <div className="surface-0 text-700 text-center">
        <div className="text-blue-600 font-bold mb-3">
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "216px" }} src="/Img/Logo.png" alt="logo" />
          </div>
          &nbsp;POWERED BY Dr.Oetker
        </div>
        <div className="text-900 font-bold text-5xl mb-3 color-code">
          Claim Process - Kartik Traders
        </div>
      </div>
      <div style={{ marginTop: 120 }}>
        <div className="card" style={{ height: "calc(135vh - 145px)" }}>
          <h5></h5>
          <DataTable
            value={primarySaleDataList}
            paginator
            scrollable
            scrollHeight="flex"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            dataKey="id"
            filterDisplay="menu"
            globalFilter={globalFilterValue1}
            header={header1}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            className="p-datatable-customers"
            showGridlines
          >
            <Column
              field="product.skuname"
              header="Sku Name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="openingStock"
              header="Opening Stock"
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="primarySaleInUnit"
              header="Primary Sale In Unit"
              style={{ width: "13%" }}
            ></Column>
            <Column
              field="primarySaleInINR"
              header="Primary Sale In Inr"
              style={{ width: "13%" }}
            ></Column>
            <Column
              field="secondarySaleInUnit"
              header="Secondary Sale In Unit"
              body={(rowData) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <span>{rowData.secondarySaleInUnit}</span>
                  <Link
                    to={"/claim-process-details"}
                    state={rowData}
                    className="custom-link"
                  >
                    <i className="pi pi-arrow-circle-up"></i>
                  </Link>
                </div>
              )}
              style={{ width: "13%" }}
            />
            <Column
              field="closingStock"
              header="Closing Stock"
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="focUnit"
              header="Foc Unit"
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="claimUnit"
              header="Claim Unit"
              style={{ width: "10%" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default ClaimProcess;
