import axios from "axios";
import getHeaders from "./headers";

export const uploadDocument = (clientData) => {
  const formData = new FormData();
  formData.append("clientData", clientData); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url: "https://dhobicart.in/poc-demo/primary-sale",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

export const primarySaleData = (data) => {
  return axios({
    method: "post",
    url: "https://dhobicart.in/poc-demo/primary-sale-data",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const uploadDocumentSecondary = (clientData) => {
  const formData = new FormData();
  formData.append("clientData", clientData); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url: "https://dhobicart.in/poc-demo/secondary-sale",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

export const secondarySaleData = (data) => {
  return axios({
    method: "post",
    url: "https://dhobicart.in/poc-demo/secondary-sale-data",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const claimProcess = (data) => {
  return axios({
    method: "post",
    url: "https://dhobicart.in/poc-demo/claim-process",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const claimProcessData = (data) => {
  return axios({
    method: "post",
    url: "https://dhobicart.in/poc-demo/claim-process-data",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const claimProcessDetailsData = (data) => {
  return axios({
    method: "post",
    url: "https://dhobicart.in/poc-demo/claim-process-details",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const clearData = () => {
  return axios({
    method: "delete",
    url: "https://dhobicart.in/poc-demo/clearData",
    headers: getHeaders(),
  });
};

// curl --location 'https://dhobicart.in/poc-demo/primary-sale' \
// --header 'Content-Type: application/json' \
// --header 'Cookie: JSESSIONID=890097332F0DD1F7128B7BDE7AF61864' \
// --form 'clientData=@"/home/oitpl6/Downloads/Book1.xlsx"'

