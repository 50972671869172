import Swal from "sweetalert2";
const alertConfig = {
  position: "top-end",
  icon: "success",
  title: "Data has been saved",
  showConfirmButton: false,
  timer: 1500,
};

export const successMessage = (title = "") => {
  Swal.fire({
    ...alertConfig,
    title: title ? title : alertConfig.title,
  });
};

export const errorMessage = (title = "") => {
  Swal.fire({
    ...alertConfig,
    icon: "error",
    title: title ? title : "Something went wrong, please try again later.",
  });
};