

const getHeaders = (contentType) => {
  console.log("contentType", contentType);
  const headers = {
    // Authorization: "Bearer sadasd",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };

  if (contentType === "multipart/form-data") {
    headers["Content-Type"] = "multipart/form-data";
  } else {
    headers["Content-Type"] = "application/json";
  }

  return headers;
};

export default getHeaders;
