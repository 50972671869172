import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./index.css";

import React, { useState } from "react";
import { Button } from "primereact/button";
import "./FormDemo.css";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [input, setInput] = useState({
    user: "",
    pass: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (input.user === "primary") {
      navigate("/primary-sale");
    } else if (input.user === "secondary") {
      navigate("/secondary-sale");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="form-demo background-image-login">
      <div className="flex justify-content-center">
        <div className="card">
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "216px" }} src="/Img/Logo.png" alt="logo" />
          </div>
          <h5 className="text-center">Login</h5>
          <form onSubmit={handleSubmit} className="p-fluid">
            <div className="field">
              <span className="p-float-label">
                <h5>UserName</h5>
                <div className="input-group has-validation">
                  <InputText
                    value={input.user}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        user: e.target.value,
                      });
                    }}
                    type="text"
                    name="username"
                    className="form-control"
                    id="username"
                  />
                </div>
              </span>
            </div>

            <div className="field">
              <span className="p-float-label">
                <h5>Password</h5>
                <div className="input-group has-validation">
                  <InputText
                    value={input.pass}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        pass: e.target.value,
                      });
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="form-control"
                    id="password"
                  />
                  {/* Move the eye icon inside the input box */}
                  <span
                    className="input-group-addon"
                    onClick={handleTogglePassword}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      left: "-10px",
                      marginLeft: "-15px",
                    }}
                  >
                    <i
                      className={`bi ${
                        !showPassword ? "bi-eye-slash-fill" : "bi-eye-fill"
                      }`}
                    />
                  </span>
                </div>
              </span>
            </div>
            <Button type="submit" label="Submit" className="mt-2" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
