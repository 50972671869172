import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useEffect, useState } from "react";
import {
  claimProcessDetailsData,
  primarySaleData,
  secondarySaleData,
  uploadDocument,
  uploadDocumentSecondary,
} from "../services/uploadDocument";
import "./FormDemo.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";

const ClaimProcessDetail = () => {
  const { state } = useLocation();
  const [primarySaleDataList, setPrimarySaleDataList] = useState([]);

   const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  console.log("state", state);
  const customerId = state.customer.id;
  const productId = state.product.id;

  const fetchData = () => {
    const data = {
      productId: productId,
      customerId: customerId,
    };
    claimProcessDetailsData(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          console.log("Res", Res);
          setPrimarySaleDataList(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => fetchData(), []);

  console.log("primarySaleDataList", primarySaleDataList);

  const paginatorLeft = (
    <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  );

   const onGlobalFilterChange1 = (e) => {
     const value = e.target.value;

     setGlobalFilterValue1(value);
   };

   const renderHeader1 = () => {
     return (
       <div className="flex justify-content-between">
         <span className="p-input-icon-left">
           <i className="pi pi-search" />
           <InputText
             value={globalFilterValue1}
             onChange={onGlobalFilterChange1}
             placeholder="Keyword Search"
           />
         </span>
       </div>
     );
   };

   const header1 = renderHeader1();

   console.log("globalFilterValue1", globalFilterValue1);

  return (
    <div className="background-image4">
      <div className="surface-0 text-700 text-center mt-4">
        <div className="text-blue-600 font-bold mb-3">
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "216px" }} src="/Img/Logo.png" alt="logo" />
          </div>
          &nbsp;POWERED BY Dr.Oetker
        </div>
        <div className="text-900 font-bold text-5xl mb-3 color-code">
          Secondary Sale - Kartik Traders
        </div>
      </div>
      <div style={{ marginTop: 120 }}>
        <div className="card" style={{ height: "calc(135vh - 145px)" }}>
          <h5></h5>
          <DataTable
            value={primarySaleDataList}
            paginator
            scrollable
            scrollHeight="flex"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            dataKey="id"
            filterDisplay="menu"
            globalFilter={globalFilterValue1}
            header={header1}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            className="p-datatable-customers"
            showGridlines
          >
            <Column
              field="orderNo"
              header="Order No"
              style={{ width: "12%" }}
            ></Column>
            <Column
              field="orderDate"
              header="Order Date"
              style={{ width: "16%" }}
            ></Column>
            <Column
              field="outletName"
              header="Outlet Name"
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="product.skuname"
              header="Sku Name"
              style={{ width: "25%" }}
            ></Column>
            <Column
              field="quantityStdUnit"
              header="Quantity Std Unit"
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="saleValue"
              header="Sale Value"
              style={{ width: "15%" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default ClaimProcessDetail;
