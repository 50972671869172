import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Login from "../pages/Login";
import Abc from "../pages/Abc";
import Xyz from "../pages/Xyz";
import ClaimProcess from "../pages/ClaimProcess";
import ClaimProcessDetail from "../pages/ClaimProcessDetail";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Login />} />
      <Route path="/primary-sale" element={<Abc />} />
      <Route path="/secondary-sale" element={<Xyz />} />
      <Route path="/claim-process" element={<ClaimProcess />} />
      <Route path="/claim-process-details" element={<ClaimProcessDetail />} />
    </Route>
  )
);

export default router;

// jobUsers 
// "USER_ACCOUNT":
// "CUSTOMER_FILE":
// "CUSTOMER_FILE_PROCESS":